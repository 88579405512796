import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { MDBAnimation, MDBCol, MDBRow } from 'mdb-react-ui-kit';

import Loading from "./components/Loading";
import Dashboard from "./pages/Dashboard";
import SideNav from './components/SideNav';
import Scheduler from './pages/Scheduler';

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <MDBAnimation reset={true} animation='fade-in' start='onLoad' duration={1000}>
        <MDBRow>
          <MDBCol size={1}>
            <SideNav />
          </MDBCol>
          <MDBCol size={10}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/scheduler" element={<Scheduler />} />
            </Routes>
          </MDBCol>
        </MDBRow>
      </MDBAnimation>
    );
  }
}

export default withAuthenticationRequired(App, {
  onRedirecting: () => <Loading />,
});
