const listOfAccounts = [
   {
      loginEmail: process.env.MARIA_LOGIN_EMAIL,
      loginPassword: process.env.MARIA_LOGIN_PASSWORD,
      user: "Maria",
      propertyIDArray: [
         { id: "780351322964363373", property: "EV3", scheduled: false },
         { id: "1071291391441341749", property: "S8", scheduled: true },
         { id: "1165951956079106794", property: "SE2", scheduled: true },
         { id: "1030435299827534111", property: "SE3", scheduled: true },
         { id: "910857717782159449", property: "A303", scheduled: true },
         { id: "910895104867725216", property: "AN17", scheduled: false }
      ]
   },
   {
      loginEmail: process.env.ALEX_LOGIN_EMAIL,
      loginPassword: process.env.ALEX_LOGIN_PASSWORD,
      user: "Alex",
      propertyIDArray: [
         { id: "1019687651335716818", property: "B9", scheduled: true },
         { id: "789194123181151805", property: "I3", scheduled: true },
         { id: "1140623727603006124", property: "C2", scheduled: true },
         { id: "1253020981964201323", property: "BD3", scheduled: true },
         { id: "1252363973567508225", property: "BD2", scheduled: true }
      ]
   },
   {
      loginEmail: process.env.ANDREW_LOGIN_EMAIL,
      loginPassword: process.env.ANDREW_LOGIN_PASSWORD,
      user: "Andrew",
      propertyIDArray: [
         { id: "969684207253039870", property: "A311", scheduled: true },
         { id: "1229876896626994689", property: "I9 SS", scheduled: true },
         { id: "1157465054606706196", property: "EG2", scheduled: true },
         { id: "895384672208363542", property: "M708", scheduled: true },
         { id: "892714062155276630", property: "H1", scheduled: true },
         { id: "1183399162504512796", property: "SM1", scheduled: true }
      ]
   },
   {
      loginEmail: process.env.ELLIOT_LOGIN_EMAIL,
      loginPassword: process.env.ELLIOT_LOGIN_PASSWORD,
      user: "Elliot",
      propertyIDArray: [
         { id: "1223279692077726031", property: "RM3", scheduled: true },
         { id: "976194407918872598", property: "B13", scheduled: true },
         { id: "1181896364527769797", property: "SMU", scheduled: true },
         { id: "831853928325655152", property: "F10", scheduled: true },
         { id: "1267555229497663544", property: "BM5", scheduled: true },
         { id: "42743449", property: "A10", scheduled: true },
         { id: "909578425542231309", property: "P2", scheduled: true }
      ]
   },
   {
      loginEmail: process.env.GEORGE_LOGIN_EMAIL,
      loginPassword: process.env.GEORGE_LOGIN_PASSWORD,
      user: "George",
      propertyIDArray: [
         { id: "978326620963810661", property: "E507", scheduled: true },
         { id: "873536889577800875", property: "I19", scheduled: true },
         { id: "768161191646426632", property: "WP6", scheduled: true },
         { id: "1013758612546152840", property: "B1", scheduled: true },
         { id: "1233359697945859570", property: "B47", scheduled: true },
         { id: "1020231892276742083", property: "EGU Short Stays", scheduled: false },
         { id: "959642591351932111", property: "KEU", scheduled: false }
      ]
   },
   {
      loginEmail: process.env.JACK_LOGIN_EMAIL,
      loginPassword: process.env.JACK_LOGIN_PASSWORD,
      user: "Jack",
      propertyIDArray: [
         { id: "1258931546705377896", property: "W33", scheduled: true },
         { id: "52300451", property: "S7", scheduled: true },
         { id: "1143469699387628881", property: "L13", scheduled: true },
         { id: "51332785", property: "B4", scheduled: true },
         { id: "1006569960903726961", property: "B5", scheduled: true },
         { id: "1264533270274504206", property: "GM38", scheduled: true }
      ]
   },
   {
      loginEmail: process.env.JEFF_LOGIN_EMAIL,
      loginPassword: process.env.JEFF_LOGIN_PASSWORD,
      user: "Jeff",
      propertyIDArray: [
         { id: "732672675427498948", property: "KH2", scheduled: true },
         { id: "980501362739259657", property: "KHU", scheduled: true },
         { id: "1018837975211214568", property: "KH1", scheduled: true },
         { id: "1222585218904462810", property: "HL", scheduled: true },
         { id: "53312417", property: "E306", scheduled: true },
         { id: "53312843", property: "E611", scheduled: true }
      ]
   },
   {
      loginEmail: process.env.JOE_LOGIN_EMAIL,
      loginPassword: process.env.JOE_LOGIN_PASSWORD,
      user: "Joe",
      propertyIDArray: [
         { id: "1211902627456843491", property: "GM11", scheduled: true },
         { id: "1189304696688647008", property: "E12", scheduled: true },
         { id: "1197883289367035862", property: "A2007", scheduled: true },
         { id: "53029237", property: "E304", scheduled: true },
         { id: "1090551910704609522", property: "KWU", scheduled: true },
         { id: "1264662398416873533", property: "SMU New", scheduled: true }
      ]
   },
   {
      loginEmail: process.env.JON_LOGIN_EMAIL,
      loginPassword: process.env.JON_LOGIN_PASSWORD,
      user: "Jon",
      propertyIDArray: [
         { id: "48682875", property: "M702", scheduled: true },
         { id: "983337507036144310", property: "WP2", scheduled: true },
         { id: "943727293662506572", property: "GT5", scheduled: true },
         { id: "979140492311735671", property: "A3006", scheduled: true },
         { id: "857520271908423210", property: "R22", scheduled: true },
         { id: "1233341960732011866", property: "VC4", scheduled: true }
      ]
   },
   {
      loginEmail: process.env.JOSHUA_LOGIN_EMAIL,
      loginPassword: process.env.JOSHUA_LOGIN_PASSWORD,
      user: "Joshua",
      propertyIDArray: [
         { id: "45626100", property: "A15", scheduled: true },
         { id: "774077690501416084", property: "E204", scheduled: true },
         { id: "871705313847044629", property: "E615", scheduled: true },
         { id: "1055334539209037132", property: "SP4", scheduled: true },
         { id: "46585566", property: "A317", scheduled: true }
      ]
   },
   {
      loginEmail: process.env.LENA_LOGIN_EMAIL,
      loginPassword: process.env.LENA_LOGIN_PASSWORD,
      user: "Lena",
      propertyIDArray: [
         { id: "1211906721328168564", property: "GM9", scheduled: true },
         { id: "1016818513174446418", property: "I8", scheduled: true },
         { id: "1030435299827534111", property: "SE3", scheduled: true },
         { id: "959642591351932111", property: "KEU", scheduled: true },
         { id: "1035492391187494859", property: "B41", scheduled: true },
         { id: "1001468830140493645", property: "KWD", scheduled: false }
      ]
   },
   {
      loginEmail: process.env.MIKE_LOGIN_EMAIL,
      loginPassword: process.env.MIKE_LOGIN_PASSWORD,
      user: "Mike",
      propertyIDArray: [
         { id: "747966868754425396", property: "BH41", scheduled: true },
         { id: "1016818513174446418", property: "I8", scheduled: true },
         { id: "837235462314777474", property: "KL28", scheduled: true },
         { id: "842904285830420573", property: "LG6", scheduled: true },
         { id: "773246422361773696", property: "S39", scheduled: false },
         { id: "857547425681870506", property: "YT31", scheduled: false }
      ]
   },
   {
      loginEmail: process.env.RICHARD_LOGIN_EMAIL,
      loginPassword: process.env.RICHARD_LOGIN_PASSWORD,
      user: "Richard",
      propertyIDArray: [
         { id: "53313118", property: "E616", scheduled: true },
         { id: "1206662076274965963", property: "GM18", scheduled: true },
         { id: "53313024", property: "E617", scheduled: true },
         { id: "53029237", property: "E304", scheduled: true },
         { id: "1090551910704609522", property: "KWU", scheduled: true },
         { id: "578267759327049149", property: "G5", scheduled: true },
         { id: "715248457594454704", property: "YT29", scheduled: true }
      ]
   },
   {
      loginEmail: process.env.SHAWN_LOGIN_EMAIL,
      loginPassword: process.env.SHAWN_LOGIN_PASSWORD,
      user: "Shawn",
      propertyIDArray: [
         { id: "1228360351371842193", property: "SM2", scheduled: true },
         { id: "1189304696688647008", property: "E12", scheduled: true },
         { id: "1197883289367035862", property: "A2007", scheduled: true },
         { id: "1092145575978666488", property: "C13", scheduled: true },
         { id: "1058831517961863011", property: "I14", scheduled: true },
         { id: "1142067162107644244", property: "I9", scheduled: true },
         { id: "1097923032205047624", property: "AJ", scheduled: false }
      ]
   },
   {
      loginEmail: process.env.SIMBA_LOGIN_EMAIL,
      loginPassword: process.env.SIMBA_LOGIN_PASSWORD,
      user: "Simba",
      propertyIDArray: [
         { id: "910913195136937691", property: "I7", scheduled: true },
         { id: "22483084", property: "EGD", scheduled: false },
         { id: "22422543", property: "EGU", scheduled: true }
      ]
   },
   {
      loginEmail: process.env.TONY_LOGIN_EMAIL,
      loginPassword: process.env.TONY_LOGIN_PASSWORD,
      user: "Tony",
      propertyIDArray: [
         { id: "953672723424856595", property: "KED", scheduled: true },
         { id: "1120526615782080853", property: "K24", scheduled: false },
         { id: "1005160515978590346", property: "WA3", scheduled: true },
         { id: "54346854", property: "BE3", scheduled: true },
         { id: "1280631831051385257", property: "KWU New", scheduled: true },
         { id: "1263245477287275721", property: "L13 New", scheduled: false },
         { id: "670498344154779262", property: "B21", scheduled: false }
      ]
   },
   {
      loginEmail: process.env.TAYLOR_LOGIN_EMAIL,
      loginPassword: process.env.TAYLOR_LOGIN_PASSWORD,
      user: "Taylor",
      propertyIDArray: [
         { id: "1273970111613890660", property: "C2 New", scheduled: true },
         { id: "1268093512590837569", property: "KWD New", scheduled: true }
      ]
   }
]

module.exports = listOfAccounts