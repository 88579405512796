import React from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';
import PropertyScheduler from '../components/PropertyScheduler';
import listOfAccounts from '../data/accounts'; // Adjust the path as necessary

const Scheduler = () => {
  // Flatten the list of properties from all accounts and include the user
  const properties = listOfAccounts.flatMap(account => 
    account.propertyIDArray.map(property => ({
      ...property,
      user: account.user
    }))
  );

  // Group properties by user
  const propertiesByUser = properties.reduce((acc, property) => {
    if (!acc[property.user]) {
      acc[property.user] = [];
    }
    acc[property.user].push(property);
    return acc;
  }, {});

  return (
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle>Scheduler</MDBCardTitle>

        <MDBAccordion>
          {Object.keys(propertiesByUser).map((user, index) => (
            <MDBAccordionItem collapseId={index.toString()} headerTitle={user} key={index}>
              {propertiesByUser[user].map((prop) => (
                <PropertyScheduler 
                  key={prop.id} 
                  property={prop.property} 
                  status={prop.scheduled ? 'Unlisted' : 'Unknown'} 
                  scheduled={prop.scheduled}
                />
              ))}
            </MDBAccordionItem>
          ))}
        </MDBAccordion>

        {Object.keys(propertiesByUser).length === 0 && (
          <MDBCardText className='text-center'>
            No Properties Found
          </MDBCardText>
        )}
      </MDBCardBody>
    </MDBCard>
  );
};

export default Scheduler;