import { useEffect, useState } from "react";
//import Dashboard from "./pages/Dashboard";
import API from "../util/API"
import React from 'react';
import moment from "moment";
import { useAuth0 } from '@auth0/auth0-react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBTextArea,
  MDBIcon,
  MDBInput,
  MDBDatepicker,
  MDBBtn,
  MDBSelect,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBChart, MDBCol, MDBRow, MDBSwitch,
  MDBScrollbar
} from 'mdb-react-ui-kit';


const Dashboard = () => {

  const { user, logout } = useAuth0();
  const [properties, setProperties] = useState([{
    "property": "",
    "index": 1,
    "data": [5.00],
    "date": ["1/01"],
    "currentAvg": 5.00,
    "difference": 0,
    "totalReviews": 1
  }])
  const [ownersList, setOwnersList] = useState([
    {
      name: "troy@ananaproperties.com",
      properties: ["A10", "A15", "A2007", "A3006", "A303", "A311", "A317", "AN17", "B1", "B13", "B21", "B4", "B41", "B5", "B9", "BE3", "BH41", "BM5", "C13", "C2", "C35", "E12", "E204", "E304", "E306", "E507", "E611", "E615", "E616", "E617", "EG2", "EGD", "EGU", "EV3", "F10", "G5", "GM11", "GM18", "GM9", "GT5", "H1", "HL", "I14", "I19", "I3", "I7", "I8", "I9", "K24", "KED", "KEU", "KH1", "KH2", "KHU", "KL28", "KWD", "KWU", "L13", "LG6", "M702", "M708", "P2", "R22", "S1", "S7", "S8", "SE2", "SE3", "SMD", "SMU", "SP4", "VW19", "VW20", "WA3", "WP2", "WP6", "YT29", "YT31", "YT32"]
    },
    {
      name: "monse@ananaproperties.com",
      properties: ["A10", "A15", "A2007", "A3006", "A303", "A311", "A317", "S1", "S7", "S8", "WA3",]
    },
    {
      name: "karen@ananaproperties.com",
      properties: ["B21", "BE3", "BH41", "G5", "GM11", "GM18", "GM9", "L13", "SM1", "SM2", "SMU",]
    },
    {
      name: "kristell@ananaproperties.com",
      properties: ["P2", "YT29", "YT31", "YT32"]
    },
    {
      name: "lizbeth@ananaproperties.com",
      properties: ["K24", "KH1", "KH2", "KHU", "KL28", "KWD", "KWU", "RM3", "SE2", "SE3", "VW19", "VW20", "VC4",]
    },
    {
      name: "silvia@ananaproperties.com",
      properties: ["B4", "B5", "BM5", "EG2", "EGD", "EGU", "EV3", "GT5", "KED", "KEU", "M702", "M708", "WP2", "WP6"]
    },
    {
      name: "tim@ananaproperties.com",
      properties: ["AN17", "E12", "E204", "E304", "E306", "E507", "E611", "E615", "E616", "E617", "F10",]
    },
    {
      name: "lucie@ananaproperties.com",
      properties: ["H1", "I14", "I19", "I3", "I7", "I8", "I9", "LG6", "R22", "SP4", "L17"]
    },
    {
      name: "saral@ananaproperties.com",
      properties: ["B1", "B13", "B41", "B9", "C13", "C2", "HL", "B47"]
    },
    {
      name: "marek@ananaproperties.com",
      properties: ["I14", "I19", "I3", "I7", "I8", "I9"]
    },
    {
      name: "israel@ananaproperties.com",
      properties: ["I14", "I19", "I3", "I7", "I8", "I9"]
    },

    {
      name: "simba@ananaproperties.com",
      properties: ["A10", "A15", "A2007", "A3006", "A303", "A311", "A317", "AN17", "B1", "B13", "B21", "B4", "B41", "B5", "B9", "BE3", "BH41", "BM5", "C13", "C2", "C35", "E12", "E204", "E304", "E306", "E507", "E611", "E615", "E616", "E617", "EG2", "EGD", "EGU", "EV3", "F10", "G5", "GM11", "GM18", "GM9", "GT5", "H1", "HL", "I14", "I19", "I3", "I7", "I8", "I9", "K24", "KED", "KEU", "KH1", "KH2", "KHU", "KL28", "KWD", "KWU", "L13", "LG6", "M702", "M708", "P2", "R22", "S1", "S7", "S8", "SE2", "SE3", "SMD", "SMU", "SP4", "VW19", "VW20", "WA3", "WP2", "WP6", "YT29", "YT31", "YT32"],
    },
  ])
  const [selectedProperty, setSelectedProperty] = useState({})
  const [selectedOwner, setSelectedOwner] = useState(ownersList[0])

  const [currentPropertyAvg, setCurrentPropertyAvg] = useState(0.00)
  const [currentWeekPropertyAvg, setCurrentWeekPropertyAvg] = useState(0.00)
  const [currentNumberProperties, setCurrentNumberProperties] = useState(0)
  const [currentWeekRev, setCurrentWeekRev] = useState(0)
  const [currentWeekFiveStar, setCurrentWeekFiveStar] = useState(0)
  const [currentBonus, setCurrentBonus] = useState(0)
  const [reviewsArray, setReviewsArray] = useState([])

  const [due, setDue] = useState("")
  const [task, setTask] = useState("")
  const [priority, setPriority] = useState("")
  //Form Values
  const [tasks, setTasks] = useState([])



  const addTask = (property) => {
    let obj = {
      due: due,
      task: task,
      priority: priority,
      property: property,
      created: moment().format("MM/DD/YYYY")
    }
    //console.log(obj)
    setTasks([...tasks, obj])
    setTask("")
    setDue("")
    setPriority("")
  }



  useEffect(() => {
    console.log(tasks)
  }, [tasks])

  useEffect(() => {
    try {
      console.log(selectedOwner)
      API.getData(selectedOwner.name).then(({ data }) => {
        console.log(data)
        setProperties(data.sortedArray)
        //Determine Bonus here
        let propertyAvg = 0
        let propertyCurrentAvg = 0
        let numProperties = 0
        let numPropertiesCurrent = 0
        let numRevEligible = data.fiveStarCount
        data.sortedArray.forEach(property => {
          propertyAvg = propertyAvg + property.currentAvg
          numProperties++
        });
        data.sortedCurrentArray.forEach(property => {
          propertyCurrentAvg = propertyCurrentAvg + property.currentAvg
          numPropertiesCurrent++
        });
        setCurrentPropertyAvg(propertyAvg / numProperties)
        setCurrentWeekPropertyAvg(propertyCurrentAvg / numPropertiesCurrent)
        setCurrentNumberProperties(numProperties)
        setCurrentWeekFiveStar(numRevEligible)
        setCurrentWeekRev(data.numCurrentWeekRev)
        setCurrentBonus(numRevEligible * 5) // number 5 stars * $5 //$5 for every 5 star
        setReviewsArray(data.reviewsArray)
        setSelectedProperty(data.sortedArray[0])
      })
    } catch (error) {
      console.error(error);
    }
  }, [selectedOwner])
  return (
    <div>
      {/* Header */}
      <h4> Welcome Back {user.name} </h4>
      {/* <h6> Properties: {currentNumberProperties} </h6>
        <h6> Overall: {currentPropertyAvg.toFixed(3)} </h6>
        <h6> Current Week Overall: {currentWeekPropertyAvg.toFixed(3)}</h6>
        <h6> Current Number of reviews: {currentWeekRev}</h6> */}

      {/* Scrolls */}

      {/* PM Selector */}
      <MDBNavbar light bgColor='light'>
        <MDBContainer className="text-center">
          <MDBScrollbar className="text-nowrap overflow-x-scroll p-2">
            {ownersList.map((owner) => (
              <MDBBtn outline color={selectedOwner.name == owner.name ? "primary" : "secondary"} size="sm" type='button' className="mx-2" onClick={() => {
                setSelectedOwner(owner)
              }} >
                {owner.name}
              </MDBBtn>
            ))}
          </MDBScrollbar>
        </MDBContainer>
      </MDBNavbar>

      <MDBNavbar light bgColor='light'>
        <MDBContainer className="text-center">
          <MDBScrollbar className="text-nowrap overflow-x-scroll p-2">
            {properties.map((property) => (
              <MDBBtn outline color={selectedProperty.property == property.property ? "primary" : "secondary"} size="sm" type='button' className="mx-2" onClick={() => {
                setSelectedProperty(property)
              }} >
                {property.property}
              </MDBBtn>
            ))}
          </MDBScrollbar>
        </MDBContainer>
      </MDBNavbar>

      <MDBRow>
        <MDBCol size={12}>

          {/* Property Cards */}
          <MDBCard className="my-5 p-3">
            <MDBCardBody>
              <MDBCardTitle>{selectedProperty?.property}</MDBCardTitle>
              <MDBCardText>
                <MDBRow>
                  <MDBCol size={2}>
                    {/* Property Avg */}
                    {selectedProperty.currentAvg < 4.7 ?
                      <h6 className="text-danger">
                        Current Avg: {selectedProperty.currentAvg}
                      </h6>
                      :
                      selectedProperty.currentAvg < 4.8 ?
                        <h6 className="text-warning">
                          Current Avg: {selectedProperty.currentAvg}
                        </h6>
                        :
                        selectedProperty.currentAvg < 4.9 ?
                          <h6 className="text-success" style={{ '--mdb-text-opacity': '.75' }}>
                            Current Avg: {selectedProperty.currentAvg}
                          </h6>
                          :
                          <h6 className="text-success">
                            Current Avg: {selectedProperty.currentAvg}
                          </h6>
                    }

                    {/* Difference */}
                    {selectedProperty.difference < 0 ? //Negative
                      <h6 color="red">
                        Difference: {selectedProperty.difference}
                      </h6>
                      :
                      selectedProperty.difference > 0 ? //Positive
                        <h6 color="red">
                          Difference: {selectedProperty.difference}
                        </h6>
                        :
                        <h6 color="red">
                          Difference: {selectedProperty.difference}
                        </h6>
                    }

                    {/* Number of Reviews */}
                    <h6>
                      Number of Reviews: {selectedProperty.totalReviews}
                    </h6>

                  </MDBCol>
                  <MDBCol size={5}>
                    <MDBChart
                      type='line'
                      data={{
                        labels: selectedProperty.date,
                        datasets: [
                          {
                            label: 'Week Avg',
                            data: selectedProperty.data,
                          },
                        ],
                      }}
                    />
                  </MDBCol>
                  <MDBCol size={5}>
                    <h6 className="text-center"> Tasks </h6>
                    <div style={{ height: 300 }}>
                      <MDBCard className="overflow-scroll h-100">
                        <MDBCardBody>
                          {/* Add Tasks */}
                          <h6>Add Task</h6>
                          <MDBRow className="my-4">
                            {/* Form */}
                            <MDBCol size={12}>
                              <MDBInput label="Task" id="taskInput"
                                value={task}
                                onChange={(event) => {
                                  setTask(event.target.value)
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow className="my-4">
                            <MDBCol size={6}>
                              <MDBDatepicker
                                label={"Due"}
                                id="taskDue"
                                onChange={(event) => {
                                  setDue(event)
                                }}
                                value={due}
                                format='mm/dd/yyyy'
                              />
                            </MDBCol>
                            <MDBCol size={6}>
                              <MDBSelect
                                className=""
                                label="Priority"
                                data={[
                                  { text: 'Low', value: 1 },
                                  { text: 'Medium', value: 2 },
                                  { text: 'High', value: 3 }
                                ]}
                                onChange={(event) => {
                                  setPriority(event.text)
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow className="my-4">
                            <MDBCol size={12} className="px-1 text-center">
                              <MDBBtn tag='a' className="rounded"
                                onClick={() => { addTask(selectedProperty.property) }}
                              >
                                <MDBIcon fas icon="plus" /> Add Task
                              </MDBBtn>
                            </MDBCol>
                          </MDBRow>


                          {tasks.filter(task => task.property === selectedProperty.property).map((task) => (
                            <MDBCard className="my-2">
                              <MDBCardBody>
                                <MDBCardText>
                                  <h6><b>Task</b> - {task?.task}</h6>
                                  <p>
                                    <b>Priority:</b> {task?.priority}<br />
                                    <b>Due:</b> {task?.due} <br />
                                    <b>Created:</b> {task?.created}<br />
                                  </p>
                                </MDBCardText>
                              </MDBCardBody>
                            </MDBCard>
                          ))}

                          {reviewsArray.filter(review => review.property_name === selectedProperty.property).map((review) => (
                            <MDBCard className="my-2">
                              <MDBCardBody>
                                <h6>Review - {review.rating} stars - {review.guest_first_name}</h6>
                                <p>
                                  <b>Check In:</b> {moment(review.checkin_date).format("MM-DD-YYYY")} <s> </s>
                                  <b>Check out:</b> {moment(review.checkout_date).format("MM-DD-YYYY")} <br /><br />
                                  <b>Public Review:</b> {review.public_review}<br /><br />

                                  {review.private_feedback ? <p><b>Private Feedback:</b> {review.private_feedback} <br /></p>
                                    : null}
                                </p>
                                <p></p>
                                <MDBCardText></MDBCardText>
                              </MDBCardBody>
                            </MDBCard>
                          ))}
                        </MDBCardBody>
                      </MDBCard>
                    </div>
                  </MDBCol>

                </MDBRow>
                {/* Permissions */}
                <hl />
                <MDBRow className="p-3 my-2">
                  <MDBCol>
                    {/* <h6 className="text-center">Permissions</h6> */}
                    <MDBRow>

                      <h6>Viewable Property Managers</h6>
                      {ownersList.map((propertyManager) => (
                        <MDBCol>
                          <MDBSwitch id='flexSwitchCheckDefault' label={propertyManager.name} checked={propertyManager.properties.includes(selectedProperty.property)} />
                        </MDBCol>
                      ))}


                      <MDBCol>
                        {/* <h6>Permissions</h6> */}
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBCardText>

            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </div>
  );
}

export default Dashboard;
