import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import {
   MDBSideNav,
   MDBSideNavMenu,
   MDBSideNavItem,
   MDBSideNavLink,
   MDBBtn,
   MDBIcon,
   MDBContainer
} from 'mdb-react-ui-kit';

const SideNav = ({
   // containerRef, 
   page, setPage }) => {
   const [slimOpen, setSlimOpen] = useState(true);
   const containerRef = useRef(null);
   const [container, setContainer] = useState();

   useEffect(() => {
      setContainer(containerRef.current);
   }, []);

   return (
      <div
         onMouseEnter={() => {
            console.log("Hovered")
            setSlimOpen(false)
         }}
         onMouseLeave={() => {
            console.log("Not Hovered")
            setSlimOpen(true)
         }}
      >

         <MDBSideNav
            backdrop={false}
            color='light'
            closeOnEsc={false}
            slim
            slimCollapsed={slimOpen}
            mode='over'
            contentRef={container}
            bgColor='dark'
            style={{ "transition-duration": "0s" }}
         >
            <MDBSideNavItem

               className='ripple d-flex justify-content-center py-4 mb-'
               style={{ borderBottom: '2px solid #f5f5f5' }}
               data-mdb-ripple-color='primary'
            >
               <MDBIcon far icon='heart' className='sidenav-slim text-white'></MDBIcon>
               <MDBIcon far icon='heart' className='sidenav-non-slim text-white'></MDBIcon>
            </MDBSideNavItem>

            <MDBSideNavMenu>
               <span className='sidenav-subheading sidenav-slim text-muted'></span>
               <span className='sidenav-subheading sidenav-non-slim text-muted'>
                  Managed
               </span>
               <MDBSideNavLink as={NavLink} to="/" activeClassName="active" style={{ "transition-duration": "0s" }}>
                  <MDBIcon fas icon="home" className='fa-fw me-3' />
                  <span className='sidenav-non-slim text-white'>Home</span>
               </MDBSideNavLink>
               <MDBSideNavLink as={NavLink} to="/scheduler" activeClassName="active" style={{ "transition-duration": "0s" }}
                  onClick={() => {
                     console.log("Scheduler")
                  }}>
                  <MDBIcon fas icon="clock" className='fa-fw me-3' />
                  <span className='sidenav-non-slim text-white'>Scheduler</span>
               </MDBSideNavLink>
            </MDBSideNavMenu>
         </MDBSideNav>
      </div>
   );
}

export default SideNav