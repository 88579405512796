import React, { useState, useEffect } from 'react';
import { MDBCardText, MDBSwitch, MDBInput, MDBCheckbox } from 'mdb-react-ui-kit';

const PropertyScheduler = ({ property, status, scheduled }) => {
  const [listingOff, setListingOff] = useState(() => {
    const saved = localStorage.getItem(`listingOff-${property}`);
    return saved !== null ? saved : '06:00';
  });
  const [listingOn, setListingOn] = useState(() => {
    const saved = localStorage.getItem(`listingOn-${property}`);
    return saved !== null ? saved : '18:00';
  });
  const [isScheduled, setIsScheduled] = useState(() => {
    const saved = localStorage.getItem(`isScheduled-${property}`);
    return saved !== null ? JSON.parse(saved) : scheduled;
  });
  const [daysOfWeek, setDaysOfWeek] = useState(() => {
    const saved = localStorage.getItem(`daysOfWeek-${property}`);
    return saved !== null ? JSON.parse(saved) : {
      Monday: true,
      Tuesday: true,
      Wednesday: true,
      Thursday: true,
      Friday: true,
      Saturday: false,
      Sunday: false,
    };
  });

  useEffect(() => {
    console.log(`listingOff-${property}:`, listingOff);
    localStorage.setItem(`listingOff-${property}`, listingOff);
  }, [listingOff, property]);

  useEffect(() => {
    console.log(`listingOn-${property}:`, listingOn);
    localStorage.setItem(`listingOn-${property}`, listingOn);
  }, [listingOn, property]);

  useEffect(() => {
    localStorage.setItem(`isScheduled-${property}`, JSON.stringify(isScheduled));
  }, [isScheduled, property]);

  useEffect(() => {
    localStorage.setItem(`daysOfWeek-${property}`, JSON.stringify(daysOfWeek));
  }, [daysOfWeek, property]);

  const handleDayChange = (day) => {
    setDaysOfWeek((prevDays) => ({
      ...prevDays,
      [day]: !prevDays[day],
    }));
  };

  return (
    <MDBCardText className='p-3'>
      <p className='mb-4'>
        Property: {property} | Status:
        <span
          style={{
            display: 'inline-block',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: status === 'Listed' ? 'green' : status === 'Unknown' ? 'gray' : 'red',
            marginLeft: '8px'
          }}
        ></span> {status}
      </p>
      <MDBSwitch
        id={`flexSwitchCheck${property}`}
        label='Scheduled'
        checked={isScheduled}
        onChange={() => setIsScheduled(!isScheduled)}
        className='mb-4'
      />

      <div className='my-4'>
        <MDBInput
          label="Listing Off"
          type="time"
          id={`listingOff${property}`}
          name={`listingOff${property}`}
          value={listingOff}
          onChange={(e) => setListingOff(e.target.value)}
        />
      </div>
      <div className='mb-4'>
        <MDBInput
          label="Listing On"
          type="time"
          id={`listingOn${property}`}
          name={`listingOn${property}`}
          value={listingOn}
          onChange={(e) => setListingOn(e.target.value)}
        />
      </div>
      <div className='mb-3'>
        <p className='mb-2'>Days of the Week:</p>
        <div className='d-flex flex-wrap'>
          {Object.keys(daysOfWeek).map((day) => (
            <div className='me-4' key={day}>
              <MDBCheckbox
                name={day}
                id={`${property}-${day}`}
                label={day}
                checked={daysOfWeek[day]}
                onChange={() => handleDayChange(day)}
                className=''
              />
            </div>
          ))}
        </div>
      </div>
    </MDBCardText>
  );
};

export default PropertyScheduler;