//React Dependencies
import React from "react";
import ReactDOM from 'react-dom/client';

import { BrowserRouter as Router } from "react-router-dom";


import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

//Authenication 
import Auth0ProviderWithHistory from "./auth0-provider-with-history"


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <App />
  <Router>
    <Auth0ProviderWithHistory>
      <App />
    </Auth0ProviderWithHistory>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
