import React from 'react';
import { MDBSpinner } from 'mdb-react-ui-kit';

const Loading = () => {
   return (
      <div className='text-center'>
         <MDBSpinner size="lg" className='m-5' role='status'>
            <span className='visually-hidden'>Loading...</span>
         </MDBSpinner>
      </div>
   )
}



export default Loading;