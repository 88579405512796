import axios from "axios";
const headers = {
   'Access-Control-Allow-Origin': '*',
   'Content-Type': 'application/json',
}

export default {
   getData: (propertyManager) => {
      return axios.get('proxy/api/db/test?startDate=05-01-2024&manager=' + propertyManager, {
         headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
         }
      })// + user?.email);
   },

   // Tasks
   createTask: () => {
      return axios.post('proxy/api/db/test?startDate=05-01-2024&manager=troy@ananaproperties.com', {
         headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
         }
      })// + user?.email);
   }
}